<template>
  <div>
    <div
      v-for="(inputField, ifIndex) in inputFields"
      :key="'input-field' + ifIndex + renderKey"
      class="relative h-7 mb-2"
    >
      <base-auto-complete-input
        :key="'input-field' + ifIndex + renderKey"
        :model-value="inputField.text"
        :input-props="{
          ...inputProps,
          containerClass: 'h-full w-full'
        }"
        :options="options"
        :disable-suggestions="!inputField.text || (inputField.text.length < 2)"
        class="h-full w-full"
        @update:modelValue="handleUpdate($event, ifIndex)"
      />
      <base-button
        v-if="inputFields.length > 1"
        class="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center h-full w-7 py-1.5 px-1.5 text-black font-normal focus:outline-none text-sm leading-4 rounded-r-sm"
        @click="removeField(ifIndex)"
      >
        <template #default>
          <base-svg
            class="h-3.5 w-3.5 text-black inline-block align-middle"
            src="icons/cross.svg"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
          />
        </template>
      </base-button>
    </div>
    <div class="flex justify-between">
      <base-button
        variant="btn-border-gray-8"
        text="Add Another"
        v-bind="{...btnProps}"
        @click="addInputField('')"
      />
      <base-button
        v-if="inputFields.length > 1"
        variant="btn-border-gray-8 align ml-4"
        text="Clear All"
        v-bind="{...btnProps}"
        @click="removeAll()"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref, watch } from 'vue';

export default {
    name: 'CustomMultiInputs',

    components: {
        BaseAutoCompleteInput: defineAsyncComponent(() => import('@/components/generic-components/BaseAutoCompleteInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    props: {
        inputProps: {
            type: Object,
            default: () => {},
            description: 'Props object for base-input(check base-input component for supported props)'
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        btnProps: {
            type: Object,
            default: () => {},
            description: 'Props object for base-button(check base-button component for supported props)'
        },
        options: {
            type: Array,
            default: () => [],
            description: 'Options for autocomplete dropdown'
        }
    },

    emits: [
        'update:modelValue'
    ],

    setup (props, { emit, slots }) {
        // input fields data binding logic
        const inputFields = ref([]);
        const addInputField = (text) => {
            inputFields.value.push({ text });
            emitUpdateEvent();
        };
        const removeField = (index) => {
            inputFields.value.splice(index, 1);
            emitUpdateEvent();
        };
        const removeAll = () => {
            inputFields.value = [];
            emitUpdateEvent();
        };
        const handleUpdate = (value, index) => {
            inputFields.value.splice(index, 1, { text: value });
            emitUpdateEvent();
        };
        const emitUpdateEvent = () => {
            emit('update:modelValue', inputFields.value.map(inputField => inputField.text));
        };

        // intializing input fields logic
        const initInputFields = () => {
            inputFields.value = [];
            if (props.modelValue && props.modelValue.length) {
                props.modelValue.forEach((text) => {
                    inputFields.value.push({ text });
                });
            } else {
                inputFields.value.push({ text: '' });
            }
        };
        onMounted(() => {
            initInputFields();
        });
        watch(
            () => props.modelValue,
            () => {
                initInputFields();
            },
            { deep: true }
        );

        // force re-render base-input on disabled prop change
        const renderKey = ref(0);
        watch(
            () => props.inputProps.disabled,
            () => {
                renderKey.value++;
            }
        );

        return {
            inputFields,
            addInputField,
            slots,
            handleUpdate,
            removeField,
            renderKey,
            removeAll
        };
    }
};
</script>
